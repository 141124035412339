<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">TECHNICAL SUPPORT</div>
    <div class="form">
      <div class="form_title">TECHNICAL SUPPORTR</div>
      <div class="form_title2">
        Did you check our FAQ section - Questions and answers?
      </div>
      <div class="form_formName">Submit An On-Line Request</div>
      <div class="form_main">
        <input
          type="text"
          placeholder="First Name *"
          v-model="from.firstName"
        />
        <input type="text" placeholder="Last Name *" v-model="from.lastName" />
        <input type="text" placeholder="Company *" v-model="from.company" />
        <input type="text" placeholder="eMail *" v-model="from.email" />
        <input type="text" placeholder="Phone" v-model="from.phone" />
        <!-- 第一个选择框的内容 Country-->
        <div>
          <el-select
            v-model="checkedCountry"
            filterable
			 no-match-text="No data"
            placeholder="Country *"
          >
            <el-option
              v-for="item in countryList"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <img src="@/assets/index/popUp.png" alt="" />
        </div>

        <!-- 第二个选择框的内容 -->
        <div>
          <el-select
            v-model="checkedGroup"
            filterable
			 no-match-text="No data"
            placeholder="Product Group"
            class="select"
            @change="checkGroup"
          >
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.value"
              :value="item"
            >
            </el-option>
          </el-select>
          <img src="@/assets/index/popUp.png" alt="" />
        </div>

        <!-- 第三个选择框的内容 -->
        <div>
          <el-select
            v-model="checkedModel"
            filterable
			 no-data-text="No data"
            placeholder="Product Model *"
            class="select"
            @change="checkModel"
          >
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.value"
              :value="item"
            >
            </el-option>
          </el-select>
          <img src="@/assets/index/popUp.png" alt="" />
        </div>

        <input
          type="text"
          placeholder="Serial Number *"
          v-model="from.serialNumber"
        />

        <!-- 第四个选择框的内容  Technical Support -->
        <div>
          <el-select
            v-model="checkedSupport"
            filterable
			 no-match-text="No data"
            placeholder="Technical Support"
            @change="checkSupport"
          >
            <el-option
              v-for="item in supportList"
              :key="item.value"
              :label="item.value"
              :value="item.type"
            >
              <!-- @change="" -->
            </el-option>
          </el-select>

          <img src="@/assets/index/popUp.png" alt="" />
        </div>
        <input
          type="text"
          placeholder="Title(Request short description) *"
          v-model="from.title"
        />
      </div>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="Request *"
        v-model="from.content"
      ></textarea>
      <div class="form_submit" @click="submit">SEND</div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
import { Loading } from "element-ui";
import countryList from "@/utils/country";
export default {
  created() {
    this.productTypeList();
    this.countryList = countryList;
  },
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "/support", title: "SUPPORT & SERVICE" },
      { path: "", title: 'TECHNICAL SUPPORT' },
    ];
  },
  data() {
    return {
      breadcrumbList: [],

      groupList: [],
      checkedGroup: "",

      modelList: [],
      checkedModel: "",
      ////////
      countryList: [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        {
          value: "豪大大香鸡排超级奶爸",
          address: "上海市嘉定区曹安公路曹安路1685号",
        },
      ],
      checkedCountry: "",
      ////////
      supportList: [
        { type: "1", value: "Technical Support" },
        { type: "2", value: "Title" },
      ],
      checkedSupport: "",
      /////////
      from: {
        company: "",
        content: "",
        country: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        productId: "",
        serialNumber: "",
        technicalSupportType: "",
        title: "",
      },
      typeList: [], //id列表
      submitFlag: true,
    };
  },
  methods: {
    checkGroup(check) {
      let that = this;
      this.typeList.forEach((item) => {
        if (item.id == check.id) {
          that.modelList = [];
          that.checkedModel = "";
          this.from.productId = "";
          item.twoTypeList.forEach((item2) => {
            that.modelList.push({ id: item2.id, value: item2.name });
          });
        }
      });
    },
    checkModel(check) {
      this.from.productId = check.id;
    },
    checkSupport(type) {
      this.from.technicalSupportType = type;
    },

    //提交
    submit() {
      let loadingInstance = Loading.service(document.body);
      let that = this;
      if (!this.submitFlag) {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return false;
      } else {
        this.submitFlag = false;
      }
      if (this.checkedCountry != "Country *") {
        this.from.country = this.checkedCountry;
      } else {
        this.$message.warning("Please select a country！");
        this.$nextTick(() => {
          loadingInstance.close();
        });
        return false;
      }

      api.saveTechnicalSupport(this.from).then((res) => {
        if (res.code == 200) {
          this.$message.success("Successful application！");
          this.$nextTick(() => {
            loadingInstance.close();
          });
          this.from = {
            company: "",
            content: "",
            country: "",
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            productId: "",
            serialNumber: "",
            technicalSupportType: "",
            title: "",
          };
          setTimeout(() => {
            that.submitFlag = true;
          }, 2000);
        } else {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          that.submitFlag = true;
          this.$message.error(res.msg);
        }
      });
    },
    //获取id列表
    productTypeList() {
      let that = this;
      api.productTypeList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.typeList = data.data;
          data.data.forEach((item) => {
            that.groupList.push({ id: item.id, value: item.name });
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
  components: {
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 186px;
  min-width: 1156px;
  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.form {
  width: 1156px;
  margin: 0 auto;

  font-family: Arial;
  font-weight: normal;
  .form_title {
    font-size: 36px;
    color: #004a9c;
    text-align: center;
    padding: 44px 0 20px;
  }
  .form_title2 {
    font-size: 20px;
    color: #5a5b5e;
    text-align: center;
    margin-bottom: 46px;
  }
  .form_formName {
    font-size: 24px;
    color: #5a5b5e;
    text-align: center;
  }
  .form_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 20px;
    input {
      width: 324px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin-bottom: 18px;
      font-size: 16px;
      color: #172f66;
      padding: 0 18px;
    }
    input:focus {
      outline: 0;
    }
    .select {
      width: 555px;
      height: 45px;
      background: #ffffff;
      border: 1px solid #004a9c;
      margin-bottom: 18px;
      position: relative;

      font-size: 16px;
      font-family: Arial;
      font-weight: normal;
      color: #172f66;

      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 12px;
        height: 6px;
      }
      > div {
        position: absolute;
        z-index: 11;
        width: 100%;
        left: 0;
        top: 45px;
        ul {
          list-style: none;
          padding: 0;
          background: #ffffff;
          li {
            padding: 0px 10px;
            line-height: 45px;
            height: 45px;
            outline: 1px solid #004a9c;
          }
        }
      }
      > input {
        height: 43px;
        border: 0;
        width: 100%;
        padding: 0;
        font-size: 16px;
      }
      > input:focus {
        border: 0;
        outline: 0px;
      }
    }
    .select1 {
      z-index: 10;
      width: 324px;
   
    }
    .select2 {
      width: 360px;
      padding: 0;
 
    }
    > div {
      position: relative;

      img {
        position: absolute;
        z-index: 11;
        right: 21px;
        top: 20px;
        width: 12px;
        height: 6px;
      }
    }
  }
  textarea {
    width: 1127px;
    height: 125px;
    background: #ffffff;
    border: 1px solid #004a9c;
    padding: 14px;

    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #172f66;
  }
  textarea:focus {
    outline: 0;
  }
  .form_submit {
    width: 263px;
    height: 45px;
    background: #004a9c;
    font-size: 18px;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    cursor: pointer;
    margin: 43px auto 53px;
  }
}
</style>

<style scoped>
div /deep/ .el-select {
  width: 360px;
  border: 1px solid #004a9c;
}
div /deep/ .el-input__suffix-inner {
  display: none;
}
div /deep/ .el-input__inner{

   border: 0;
}
</style>